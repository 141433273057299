











































































































































































































































import Vue from 'vue';
import sleep from '@/util/sleep';
import { ApiResource } from '@/types';
import { http } from '@/http';

export default Vue.extend({
  data() {
    return {
      rateLimit: 1,
      loading: false,
      complete: false,
      download: false,
      user: {
        data: {
          account: null as ApiResource.Account | null,
          icon: null as Blob | null,
        },
        value: 0,
        total: 1,
      },
      commission: {
        list: {
          all: [] as ApiResource.Commission[],
          value: 0,
          total: 1,
        },
        files: { // Files where user_id is user.id
          all: [] as ApiResource.CommissionFile[],
          current: {
            all: [] as ApiResource.CommissionFile[],
            value: 0,
            total: 1,
          },
          data: [] as Blob[],
          value: 0,
          total: 1,
        },
        messages: { // Messages where user_id is user.id
          all: [] as ApiResource.CommissionMessage[],
          current: {
            all: [] as ApiResource.CommissionMessage[],
            value: 0,
            total: 1,
          },
          value: 0,
          total: 1,
        },
      },
      emails: {
        all: [] as ApiResource.Email[],
        value: 0,
        total: 1,
      },
      events: { // User context events
        all: [] as ApiResource.Event[],
        value: 0,
        total: 1,
      },
      publicKeyCredentials: {
        all: [] as ApiResource.PublicKeyCredential[],
        value: 0,
        total: 1,
      },
    };
  },
  methods: {
    sleep,
    async all(): Promise<void> {
      this.loading = true;
      await this.fetchAccount();
      // TODO: User icon as blob
      await this.fetchCommissions();
      await this.fetchCommissionFiles();
      await this.fetchCommissionMessages();
      await this.fetchEmailAddresses();
      await this.fetchContextEvents();
      await this.fetchPublicKeyCredentials();
      this.loading = false;
      this.complete = true;
    },
    async fetchAccount(): Promise<void> {
      await this.sleep(this.rateLimit);

      const data = await this.$store.dispatch('account/GetAccount');

      this.user.data.account = data.data;

      // this.user.value = 0.5;

      // const blob = await http.get(res.data.data.icon_url, {
      //   responseType: 'blob',
      // });

      this.user.value = 1;

      // this.user.data.icon = res.data.data;
    },
    async fetchCommissions(limit = 100, offset = 0): Promise<void> {
      await this.sleep(this.rateLimit);

      const data = await this.$store.dispatch('account/ListCommissions', {
        query: {
          limit,
          offset,
        },
      });

      this.commission.list.all.push(...data.data);
      this.commission.list.value += data.data.length;

      if (this.commission.list.value === limit + offset) {
        this.commission.list.total = limit + limit + offset;

        return this.fetchCommissions(
          (limit + limit) < 100 ? limit + limit : Math.max(limit, 100),
          limit + offset,
        );
      }

      this.commission.list.total = this.commission.list.value;

      return void 0;
    },
    async fetchCommissionFiles(): Promise<void> {
      // Foreach COMMISSION_ID in loaded commissions
      // Get all files recursively, where user_id is current user
      for (let i = 0; i < this.commission.list.all.length; i += 1) {
        const commission = this.commission.list.all[i];
        // eslint-disable-next-line no-await-in-loop
        await this.fetchCommissionFilesFrom(commission.id);
      }

      this.commission.files.total = this.commission.files.value;
    },
    async fetchCommissionFilesFrom(COMMISSION_ID: string, limit = 100, offset = 0): Promise<void> {
      await this.sleep(this.rateLimit);

      if (offset === 0) { // Reset
        this.commission.files.current.all = [];
        this.commission.files.current.value = 0;
        this.commission.files.current.total = 1;
      }

      const data = await this.$store.dispatch('commission/file/List', {
        COMMISSION_ID,
        query: {
          limit,
          offset,
          user_id: this.$store.getters['account/Account'].id,
        },
      });

      this.commission.files.all.push(...data.data);
      this.commission.files.value += data.data.length;
      this.commission.files.total = this.commission.files.value + 1;

      for (let i = 0; i < data.data.length; i += 1) {
        const file = data.data[i];

        if (this.download && file.is_downloadable && file.is_uploaded) {
          // eslint-disable-next-line no-await-in-loop
          await this.sleep(this.rateLimit);
          // eslint-disable-next-line no-await-in-loop
          const download = await this.$store.dispatch('commission/file/Download', {
            COMMISSION_ID,
            FILE_ID: file.id,
          });

          // eslint-disable-next-line no-await-in-loop
          const res = await http.get(download.data.url, {
            responseType: 'blob',
          });

          this.commission.files.data.push(res.data);
        } else {
          this.commission.files.data.push(new Blob(undefined));
        }
      }

      this.commission.files.current.all.push(...data.data);
      this.commission.files.current.value += data.data.length;

      if (this.commission.files.current.value === limit + offset) {
        this.commission.files.current.total = limit + limit + offset;

        return this.fetchCommissionFilesFrom(
          COMMISSION_ID,
          (limit + limit) < 100 ? limit + limit : Math.max(limit, 100),
          limit + offset,
        );
      }

      this.commission.files.current.total = this.commission.files.current.value;

      return void 0;
    },
    async fetchCommissionMessages(): Promise<void> {
      // Foreach COMMISSION_ID in loaded commissions
      // Get all messages recursively, where user_id is current user
      for (let i = 0; i < this.commission.list.all.length; i += 1) {
        const commission = this.commission.list.all[i];
        // eslint-disable-next-line no-await-in-loop
        await this.fetchCommissionMessagesFrom(commission.id);
      }

      this.commission.messages.total = this.commission.messages.value;
    },
    // eslint-disable-next-line max-len
    async fetchCommissionMessagesFrom(COMMISSION_ID: string, limit = 100, offset = 0): Promise<void> {
      await this.sleep(this.rateLimit);

      if (offset === 0) { // Reset
        this.commission.messages.current.all = [];
        this.commission.messages.current.value = 0;
        this.commission.messages.current.total = 1;
      }

      const data = await this.$store.dispatch('commission/message/List', {
        COMMISSION_ID,
        query: {
          limit,
          offset,
          user_id: this.$store.getters['account/Account'].id,
        },
      });

      this.commission.messages.all.push(...data.data);
      this.commission.messages.value += data.data.length;
      this.commission.messages.total = this.commission.files.value + 1;

      this.commission.messages.current.all.push(...data.data);
      this.commission.messages.current.value += data.data.length;

      if (this.commission.messages.current.value === limit + offset) {
        this.commission.messages.current.total = limit + limit + offset;

        return this.fetchCommissionFilesFrom(
          COMMISSION_ID,
          (limit + limit) < 100 ? limit + limit : Math.max(limit, 100),
          limit + offset,
        );
      }

      this.commission.messages.current.total = this.commission.messages.current.value;

      return void 0;
    },
    async fetchEmailAddresses(): Promise<void> {
      await this.sleep(this.rateLimit);

      const data = await this.$store.dispatch('account/email/ListEmails');

      this.emails.value = data.data.length;
      this.emails.total = data.data.length;
      this.emails.all.push(...data.data);
    },
    async fetchContextEvents(limit = 100, offset = 0): Promise<void> {
      await this.sleep(this.rateLimit);

      const data = await this.$store.dispatch('account/ListContextEvents', {
        query: {
          limit,
          offset,
        },
      });

      this.events.all.push(...data.data);
      this.events.value += data.data.length;

      if (this.events.value === limit + offset) {
        this.events.total = limit + limit + offset;

        return this.fetchContextEvents(
          (limit + limit) < 100 ? limit + limit : Math.max(limit, 100),
          limit + offset,
        );
      }

      this.events.total = this.events.value;

      return void 0;
    },
    async fetchPublicKeyCredentials(): Promise<void> {
      await this.sleep(this.rateLimit);

      const data = await this.$store.dispatch('account/mfa/ListPublicKeyCredentials');

      this.publicKeyCredentials.all.push(...data.data);

      this.publicKeyCredentials.value = 1;
    },
    saveAll() {
      const now = Date.now();

      const json = {
        _local_data: {
          time: now / 1000,
          local_storage: window.localStorage,
          session_storage: window.sessionStorage,
          cookie: document.cookie,
          dnt: window.doNotTrack,
        },
        account: this.user.data.account,
        commissions: this.commission.list.all,
        commission_messages: this.commission.messages.all,
        commission_files: this.commission.files.all,
        email_addresses: this.emails.all,
        events: this.events.all,
        public_key_credentials: this.publicKeyCredentials.all,
      };

      this.saveAsJson(JSON.stringify(json), 'auftrag-data-export');
    },
    saveAsJson(data: BlobPart, name: string) {
      const a = document.createElement('a');
      const file = new Blob([data], { type: 'text/json' });
      a.href = URL.createObjectURL(file);
      a.download = `${name}.json`;
      a.click();
    },
  },
});
