var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('BaseMessage',{staticClass:"is-danger"},[_c('i18n',{attrs:{"path":"warning.text"},scopedSlots:_vm._u([{key:"strong",fn:function(){return [_c('strong',{domProps:{"textContent":_vm._s(_vm.$t('warning.strong'))}})]},proxy:true}])})],1),_c('BaseButton',{attrs:{"is-loading":_vm.loading,"disabled":_vm.complete},domProps:{"textContent":_vm._s(_vm.$t('export'))},on:{"click":_vm.all}}),_c('hr'),_c('BaseTable',{staticClass:"is-fullwidth is-hoverable"},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('table.head.data'))}}),_c('th',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$t('table.head.loaded'))}}),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('table.head.progress'))}})])]),_c('tbody',[_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.account'))}}),_c('p',[_c('small',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('data.account'))}}),_vm._v(" "),_c('RouterLink',{attrs:{"to":{ name: 'account' }},domProps:{"textContent":_vm._s(_vm.$t('link.account'))}})],1)])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.user.value)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.user.value === _vm.user.total,
            },attrs:{"value":_vm.user.value,"max":_vm.user.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.commissions'))}}),_c('p',[_c('small',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('data.commissions'))}}),_vm._v(" "),_c('RouterLink',{attrs:{"to":{ name: 'account.commissions' }},domProps:{"textContent":_vm._s(_vm.$t('link.commissions'))}})],1)])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.commission.list.all.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.commission.list.value === _vm.commission.list.total,
            },attrs:{"value":_vm.commission.list.value,"max":_vm.commission.list.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.commissionFiles'))}}),_c('p',[_c('small',{domProps:{"textContent":_vm._s(_vm.$t('data.commissionFiles'))}})])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.commission.files.data.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.commission.files.data.length === _vm.commission.files.total,
            },attrs:{"value":_vm.commission.files.data.length,"max":_vm.commission.files.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.commissionMessages'))}}),_c('p',[_c('small',{domProps:{"textContent":_vm._s(_vm.$t('data.commissionMessages'))}})])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.commission.messages.all.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.commission.messages.value === _vm.commission.messages.total,
            },attrs:{"value":_vm.commission.messages.value,"max":_vm.commission.messages.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.emails'))}}),_c('p',[_c('small',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('data.emails'))}}),_vm._v(" "),_c('RouterLink',{attrs:{"to":{ name: 'account.emails' }},domProps:{"textContent":_vm._s(_vm.$t('link.emails'))}})],1)])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.emails.all.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.emails.value === _vm.emails.total,
            },attrs:{"value":_vm.emails.value,"max":_vm.emails.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.events'))}}),_c('p',[_c('small',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('data.events'))}}),_vm._v(" "),_c('RouterLink',{attrs:{"to":{ name: 'feed.context_events' }},domProps:{"textContent":_vm._s(_vm.$t('link.events'))}})],1)])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.events.all.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.events.value === _vm.events.total,
            },attrs:{"value":_vm.events.value,"max":_vm.events.total}})],1)]),_c('tr',[_c('td',[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('category.publicKeyCredentials'))}}),_c('p',[_c('small',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('data.publicKeyCredentials'))}}),_vm._v(" "),_c('RouterLink',{attrs:{"to":{ name: 'account.u2f' }},domProps:{"textContent":_vm._s(_vm.$t('link.publicKeyCredentials'))}})],1)])]),_c('td',{attrs:{"align":"right"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.publicKeyCredentials.all.length)}})]),_c('td',[_c('BaseProgress',{staticClass:"is-large",class:{
              'is-link': _vm.publicKeyCredentials.value === _vm.publicKeyCredentials.total,
            },attrs:{"value":_vm.publicKeyCredentials.value,"max":_vm.publicKeyCredentials.total}})],1)])]),_c('tfoot',[_c('tr',[_c('th',{attrs:{"colspan":"2"}}),_c('td',[_c('BaseButton',{staticClass:"is-fullwidth",attrs:{"is-loading":_vm.loading,"disabled":!_vm.complete},domProps:{"textContent":_vm._s(_vm.$t('download'))},on:{"click":function($event){$event.preventDefault();return _vm.saveAll($event)}}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }