










import Vue from 'vue';
import DataExportComponent from '@/components/account/DataExport.vue';

export default Vue.extend({
  components: {
    DataExportComponent,
  },
});
